import { Vue } from 'vue-property-decorator';
import ApiError from './ApiError';

/**
 * Simple error wrapper class to normalize error cases
 * Used for errors that are already handled in the axios interceptors
 * Without throwing a custom exception, the interceptor chain is canceled by returning a new Promise, 
 * which leads to the `finally`-Block never being reached.
 * This approach is not optimal and needs to be reviewed in subsequent releases
 */
export default class GeneralApiError extends ApiError {
  constructor(status: number, message: string, data: any) {
    super(status, message, data);
  }
}