import Parseable from '@/misc/Parseable';

export default class Invoice extends Parseable {
  public id!: string;
  public startDate!: string;
  public endDate!: string;
  public clicks!: number;
  public total!: number;

  public static parseFromObject(obj: any): Invoice {
    const invoice = new Invoice();
    Object.assign(invoice, obj);
    return invoice;
  }

  public parseToObject(): Invoice {
    return { ...this };
  }
}