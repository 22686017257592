import Address from '@/interfaces/Address.interface';
import Contact from '@/interfaces/Contact.interface';
import Coordinates from '@/interfaces/Coordinates.interface';
import OpeningHours from '@/interfaces/OpeningHours.interface';
import Parseable from '@/misc/Parseable';

export default class Store extends Parseable {
  public static INTERVAL_DELIMITER = '\n ';
  public static TIME_DELIMITER = ' - ';

  public id?: string;
  public name?: string;
  public taxId?: string;
  public imprint?: string;
  public webPage?: string;
  public address!: Address;
  public coordinates!: Coordinates;
  public contact!: Contact;
  public openingHoursEntity!: OpeningHours;
  public status: boolean = true;
  public generateInvoices: boolean = false;

  public constructor() {
    super();
    this.address = { street: '', houseNr: '', zip: '', city: '', country: '' };
    this.coordinates = { lat: 52.510494, lng: 13.396764 }; // Center of Germany
    this.contact = { email: '' };
    this.openingHoursEntity = Store.getDefaultOpeningHours();
  }

  public static parseFromObject(object: any): Store {
    const store = new Store();
    if (object.location) {
      store.coordinates = { 
        lat: object.location.coordinates[1],
        lng: object.location.coordinates[0] 
      };
    }

    if (object.openingHours) {
      // Loops through each day:
      Object.keys(store.openingHoursEntity).forEach(key => {
        let objValue: { closed: boolean, hours: string[][] } | undefined;
        // If value for day is set (store is not closed on that day):
        if (object.openingHours.hasOwnProperty(key)) {
          const intervals: string[][] = [];
          const parts: string[] = object.openingHours[key].split(this.INTERVAL_DELIMITER);
          parts.forEach(interval => {
            intervals.push(interval.split(this.TIME_DELIMITER));
          });
          objValue = { closed: false, hours: intervals };
        } else { // Store is closed
          objValue = { closed: true, hours: [['', '']] };
        }
        // @ts-ignore
        store.openingHoursEntity[key as keyof OpeningHours] = objValue;
      });
    }
    
    Object.assign(store, object);

    return store;
  }

  /**
   * Creates object with default values for opening hours.
   * For all days, closed is set to false and an array for the actual hours is initialized.
   * @returns Object with default values for opening hours.
   */
  public static getDefaultOpeningHours(): OpeningHours {
    return { 
      monday: { closed: false, hours: [['', '']] },
      tuesday: { closed: false, hours: [['', '']] },
      wednesday: { closed: false, hours: [['', '']] },
      thursday: { closed: false, hours: [['', '']] },
      friday: { closed: false, hours: [['', '']] },
      saturday: { closed: false, hours: [['', '']] },
      sunday: { closed: false, hours: [['', '']] },
    };
  }

  public parseToObject(): Partial<Store> {
    return { ...this };
  }

  /**
   * Creates object for opening hours with data structure like stored in backend.
   * For each day, opening hours are stored as strings (e.g. 10:00-12:45).
   * If store is closed on that day, entry is set to undefined.
   * @returns Object with backend data structure.
   */
  public getOpeningHoursAsString(): any {
    if (this.openingHoursEntity) {
      let obj = {};
      // Loop through each day:
      Object.keys(this.openingHoursEntity).forEach(key => {
        // @ts-ignore
        const entry: { closed: boolean, hours: string[][] } = this.openingHoursEntity[key];
        let objValue: string | undefined;
        if (entry.closed) {
          objValue = undefined;
        } else {
          const filteredHours = entry.hours.filter(e => e[0] || e[1]); // ignore entries that are completely empty
          objValue = filteredHours.map(e => 
            e.join(Store.TIME_DELIMITER)    // Maps from- and to-time (e.g. [10:00, 12:00] => 10:00 - 12:00)
          ).join(Store.INTERVAL_DELIMITER); // Maps multiple entries (used for fractional opening Hours) (e.g. ['10:00 - 12:00', '14:00 - 18:00'] => 10:00 - 12:00;14:00 - 18:00)
        }
        // Appends value for day to object:
        obj = { ...obj,
          [key]: objValue
        };
      });
      return obj;
    }
    return undefined;
  }
}