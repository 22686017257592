import { AxiosResponse } from 'axios';
import { ENTITY_CLIENT } from '../misc/EntityClient';

export default class ReviewRepository {
  public static getAllForDeal(dealId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`api/review/deal/${dealId}`);
  }

  public static delete(reviewId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.delete(`api/review/${reviewId}`);
  }
}