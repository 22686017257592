import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // Colors
        primary: '#5ace92',
        secondary: '#FF922a',
        accent: '#82B1FF',
        error: '#FF5F56',
        info: '#01c1e5',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#ffffff',
        'background-accent': 'f0f6ff',
        surface: '#ffffff',
        shadow: '#cccccc',
        border: '#ededed',
        rating: '#fcd25b',
        scrollbar: '#d5d7dd',
        'app-bar': '#49ab79',
        'navigation-drawer': '#f2f2f2',
        // Fonts
        'on-background': '#000000',
        'on-surface': '#000000',
        'on-primary': '#ffffff',
        'on-app-bar': '#ffffff',
      },
    },
  },
});
