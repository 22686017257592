import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { ENTITY_CLIENT } from '../misc/EntityClient';

export default class TrackingRepository {
  public static getByStore(storeId: string, fromDate: DateTime, toDate: DateTime): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post(`api/dealViewTracking/store/${storeId}`, { 
      from: fromDate.toISO(), 
      to: toDate.toISO()
    });
  }

  public static getByDeal(dealId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post(`api/dealViewTracking/deal/${dealId}`);
  }
}