import Store from '@/models/Store.model';
import { AxiosResponse } from 'axios';
import { ENTITY_CLIENT } from '../misc/EntityClient';

export default class StoreRepository {
  public static getAll(): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get('api/store');
  }

  public static getById(id: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`api/store/${id}`);
  }

  public static create(store: any): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post('api/store', store);
  }

  public static update(store: any): Promise<AxiosResponse> {
    return ENTITY_CLIENT.patch(`api/store/${store.id}`, store);
  }

  public static register(store: any): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post('store', store);
  }
}