import { RootState } from '@/interfaces/StoreStateInterfaces';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import authStore from './auth.store';
import dealerStore from './dealer.store';
import storesStore from './stores.store';
import dealStore from './deal.store';
import applicationStore from './application.store';
import reviewStore from './review.store';
import trackingDataStore from './dealTrackingData.store';
import invoiceStore from './invoice.store';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  key: process.env.VUE_APP_VUEX_KEY,
  modules: ['auth', 'application'] // only saves auth and application module
});

const store: StoreOptions<RootState> = {
  modules: {
    application: {
      namespaced: true,
      ...applicationStore,
    },
    auth: {
      namespaced: true,
      ...authStore,
    },
    dealer: {
      namespaced: true,
      ...dealerStore,
    },
    stores: {
      namespaced: true,
      ...storesStore,
    },
    deals: {
      namespaced: true,
      ...dealStore,
    },
    review: {
      namespaced: true,
      ...reviewStore,
    },
    trackingData: {
      namespaced: true,
      ...trackingDataStore,
    },
    invoices: {
      namespaced: true,
      ...invoiceStore,
    }
  },
  plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
