import Deal from '@/models/Deal.model';
import { AxiosResponse } from 'axios';
import { ENTITY_CLIENT } from '../misc/EntityClient';

export default class DealRepository {
  public static getById(dealId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`api/deal/${dealId}`);
  }

  public static getByStore(storeId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`api/deal/store/${storeId}`);
  }

  public static getFileById(id: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`api/file/${id}`, {
      responseType: 'blob'
    });
  }

  public static uploadFile(dealId: string, file: File): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post(`api/deal/${dealId}/file`, file, {
      headers: {
        'Content-Type': file.type
      }
    });
  }

  public static removeFile(dealId: string, fileId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.delete(`api/deal/${dealId}/file/${fileId}`);
  }

  public static getAllCategories(): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get('api/category/');
  }

  public static create(deal: Deal): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post('api/deal', deal);
  }

  public static update(deal: Deal): Promise<AxiosResponse> {
    return ENTITY_CLIENT.patch(`api/deal/${deal.id}`, deal);
  }

  public static getIcon(dealId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`api/deal/${dealId}/icon`);
  }

  public static reactivate(deal: Deal): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post(`api/deal/${deal.id}/duplicate`, deal);
  }
}