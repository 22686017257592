import InvoiceRepository from '@/api/repositories/InvoiceRepository';
import { InvoiceState, RootState } from '@/interfaces/StoreStateInterfaces';
import Invoice from '@/models/Invoice.model';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import FileSaver from 'file-saver';
import { DateTime } from 'luxon';

export const enum InvoiceStoreActions {
  GET_BY_STORE = 'GET_BY_STORE',
  DOWNLOAD_INVOICE_BY_ID = 'DOWNLOAD_INVOICE_BY_ID'
}

export const enum InvoiceStoreMutations {
  SET_INVOICES_BY_STORE = 'SET_INVOICES_BY_STORE',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum InvoiceStoreGetters {
  INVOICES_BY_STORE = 'INVOICES_BY_STORE'
}

function initialInvoiceState(): InvoiceState {
  return {
    items: []
  };
}

const store: InvoiceState = initialInvoiceState();

const actions: ActionTree<InvoiceState, RootState> = {
  [InvoiceStoreActions.GET_BY_STORE]: async ({ commit }, storeId: string): Promise<Invoice[]> => {
    const response = await InvoiceRepository.getAllByStoreId(storeId);
    const invoicesByStore = (Invoice.parseFromArray(response.data) as Invoice[]);
    commit(InvoiceStoreMutations.SET_INVOICES_BY_STORE, invoicesByStore);
    return invoicesByStore;
  },
  [InvoiceStoreActions.DOWNLOAD_INVOICE_BY_ID]: async ({ commit }, payload: { invoicePrefix: string, invoice: Invoice }): Promise<void> => {
    const response = await InvoiceRepository.getInvoiceFileById(payload.invoice.id);
    FileSaver.saveAs(response.data, `${payload.invoicePrefix}_${DateTime.fromISO(payload.invoice.endDate).toFormat('yyyy-MM-dd')}.pdf`);
  }
};

const mutations: MutationTree<InvoiceState> = {
  [InvoiceStoreMutations.SET_INVOICES_BY_STORE]: (state: InvoiceState, value: Invoice[]) => {
    state.items = value;
  },
  [InvoiceStoreMutations.CLEAR_STORE]: (state: InvoiceState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialInvoiceState());
  }
};

const getters: GetterTree<InvoiceState, RootState> = {
  [InvoiceStoreGetters.INVOICES_BY_STORE]: (state: InvoiceState) => state.items,
};

const invoiceStore: Module<InvoiceState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default invoiceStore;