import ReviewRepository from '@/api/repositories/ReviewRepository';
import { ReviewState, RootState } from '@/interfaces/StoreStateInterfaces';
import Review from '@/models/Review.model';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const enum ReviewStoreActions {
  GET_BY_DEAL = 'GET_BY_DEAL',
  DELETE = 'DELETE'
}

export const enum ReviewStoreMutations {
  SET_BY_DEAL = 'SET_BY_DEAL',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum ReviewStoreGetters {
  REVIEWS_BY_DEAL = 'REVIEWS_BY_DEAL',
}

function initialReviewState(): ReviewState {
  return {
    reviewsByDeal: [],
  };
} 

const store: ReviewState = initialReviewState();

const actions: ActionTree<ReviewState, RootState> = {
  [ReviewStoreActions.GET_BY_DEAL]: async ({ commit }, dealId: string): Promise<Review[]> => {
    const response = await ReviewRepository.getAllForDeal(dealId);
    const reviews = Review.parseFromArray(response.data) as Review[];
    commit(ReviewStoreMutations.SET_BY_DEAL, reviews);
    return reviews;
  },
  [ReviewStoreActions.DELETE]: async ({ commit }, reviewId: string): Promise<any> => {
    const response = await ReviewRepository.delete(reviewId);
    return response.data;
  }
};

const mutations: MutationTree<ReviewState> = {
  [ReviewStoreMutations.SET_BY_DEAL]: (state: ReviewState, value: Review[]) => {
    state.reviewsByDeal = value;
  },
  [ReviewStoreMutations.CLEAR_STORE]: (state: ReviewState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialReviewState());
  }
};

const getters: GetterTree<ReviewState, RootState> = {
  [ReviewStoreGetters.REVIEWS_BY_DEAL]: (state: ReviewState) => {
    return state.reviewsByDeal;
  },
};

const reviewStore: Module<ReviewState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default reviewStore;