import { AxiosResponse } from 'axios';
import { AUTH_CLIENT } from '../misc/AuthClient';

export default class AuthRepository {
  public static login(payload: { email: string, password: string }): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('auth/dealerLogin', payload);
  }

  public static initiatePasswordReset(email: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.post('auth/reset-password', { 
      email: email,
      type: 'DEALER'
     });
  }

  public static resetPassword(token: string, password: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.patch('auth/reset-password', {
      token: token,
      password: password
    });
  }

  public static verifiyEmailWithToken(email: string, token: string): Promise<AxiosResponse> {
    return AUTH_CLIENT.patch('auth/email-verification', {
      email: email,
      token: token
    });
  }
}
