import { DealerRoles } from '@/enum/DealerRoles.enum';
import Parseable from '@/misc/Parseable';
import Store from './Store.model';

export default class Dealer extends Parseable {
  public id?: string;
  public email?: string;
  public firstName?: string;
  public lastName?: string;
  public store: Store | string | null = null; // type string for sending id to backend
  public isVerified: boolean = false;
  public status: boolean = true;
  public role: DealerRoles = DealerRoles.DEALER;

  public static parseFromObject(object: any): Dealer {
    const dealer = new Dealer();
    Object.assign(dealer, object);
    return dealer;
  }

  public parseToObject(): Partial<Dealer> {
    return { ...this };
  }

  get isAdmin(): boolean {
    return this.role == DealerRoles.ADMIN;
  }

  get isStoreAdmin(): boolean {
    return this.role == DealerRoles.STORE_ADMIN;
  }
}
