import Vue from 'vue';
import VueRouter from 'vue-router';
import { ROUTE_DASHBOARD, ROUTE_DEAL, ROUTE_DEALER, ROUTE_DEALS, ROUTE_HOME, ROUTE_INVOICES, ROUTE_LOGIN, ROUTE_PASSWORD, ROUTE_REGISTER, ROUTE_STORE, ROUTE_STORES, ROUTE_TRACKING } from '@/router/routes';
import store from '@/store';
import { AuthStoreGetters } from '@/store/auth.store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: ROUTE_LOGIN,
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.view.vue'),
  },
  {
    path: '/register',
    name: ROUTE_REGISTER,
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.view.vue'),
  },
  {
    path: '/password',
    name: ROUTE_PASSWORD,
    component: () => import(/* webpackChunkName: "password" */ '../views/Password.view.vue'),
  },
  {
    path: '/home',
    name: ROUTE_HOME,
    beforeEnter: async (to: any, from: any, next: any) => {
      // Check if token is set. If not, redirect to login
      if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
        await next();
      } else {
        await next({ name: ROUTE_LOGIN });
      }
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.view.vue'),
    children: [
      {
        path: '/store/:id',
        name: ROUTE_STORE,
        component: () => import(/* webpackChunkName: "store" */ '../views/Store.view.vue'),
        props: true,
        children: [
          {
            path: 'dashboard',
            name: ROUTE_DASHBOARD,
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.view.vue'),
          },
          {
            path: 'deals',
            name: ROUTE_DEALS,
            component: () => import(/* webpackChunkName: "deals" */ '../views/Deals.view.vue'),
          },
          {
            path: 'invoices',
            name: ROUTE_INVOICES,
            component: () => import(/* webpackChunkName: "invoices" */ '../views/Invoices.view.vue'),
          },
          {
            path: 'tracking',
            name: ROUTE_TRACKING,
            component: () => import(/* webpackChunkName: "tracking" */ '../views/TrackingData.view.vue'),
          },
        ]
      },
      {
        path: '/stores',
        name: ROUTE_STORES,
        component: () => import(/* webpackChunkName: "stores" */ '../views/Stores.view.vue'),
      },
      {
        path: '/dealer',
        name: ROUTE_DEALER,
        component: () => import(/* webpackChunkName: "dealer" */ '../views/Dealer.view.vue'),
      },
      {
        path: '/deal/:dealId',
        name: ROUTE_DEAL,
        component: () => import(/* webpackChunkName: "deal" */ '../views/Deal.view.vue'),
        props: true
      },
    ]
  },
  {
    path: '*',
    redirect: { name: ROUTE_HOME }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
