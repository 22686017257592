import StoreRepository from '@/api/repositories/StoreRepository';
import { RootState, StoresState } from '@/interfaces/StoreStateInterfaces';
import Store from '@/models/Store.model';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationStoreMutations } from './application.store';

export const enum StoresStoreActions {
  GET_ALL = 'GET_ALL',
  GET_BY_ID = 'GET_BY_ID',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  REGISTER_STORE = 'REGISTER_STORE'
}

export const enum StoresStoreMutations {
  SET_STORES = 'SET_STORES',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum StoresStoreGetters {
  STORES = 'STORES',
}

function initialStoreState(): StoresState {
  return {
    stores: []
  };
}

const store: StoresState = initialStoreState();

const actions: ActionTree<StoresState, RootState> = {
  [StoresStoreActions.GET_ALL]: async ({ commit }): Promise<Store[]> => {
    const response = await StoreRepository.getAll();
    const stores = Store.parseFromArray(response.data) as Store[];
    commit(StoresStoreMutations.SET_STORES, stores);
    return stores;
  },
  [StoresStoreActions.GET_BY_ID]: async ({ commit }, id: string): Promise<Store> => {
    const response = await StoreRepository.getById(id);
    return Store.parseFromObject(response.data);
  },
  [StoresStoreActions.CREATE]: async ({ commit }, store: Store): Promise<Store> => {
    // Prepare data: add property for location and convert opening hours to string:
    const storeApiEntity = { 
      ...store, 
      location: [store.coordinates.lng, store.coordinates.lat],
      openingHours: store.getOpeningHoursAsString()
    };
    const response = await StoreRepository.create(storeApiEntity);
    return Store.parseFromObject(response.data) as Store;
  },
  [StoresStoreActions.UPDATE]: async ({ commit, dispatch }, store: Store): Promise<Store> => {
    // Prepare data: add property for location and convert opening hours to string:
    const storeApiEntity = { 
      ...store, 
      location: [store.coordinates.lng, store.coordinates.lat],
      openingHours: store.getOpeningHoursAsString()
    };
    const response = await StoreRepository.update(storeApiEntity);
    const respStore = Store.parseFromObject(response.data) as Store;
    commit('application/' + ApplicationStoreMutations.SET_STORE, respStore, { root: true });
    return respStore;
  },
  [StoresStoreActions.REGISTER_STORE]: async ({ commit }, store: Store): Promise<Store> => {
    // Prepare data: add property for location and convert opening hours to string:
    const storeApiEntity = { 
      ...store, 
      location: [store.coordinates.lng, store.coordinates.lat],
      openingHours: store.getOpeningHoursAsString()
    };
    const response = await StoreRepository.register(storeApiEntity);
    return Store.parseFromObject(response.data) as Store;
  }
};

const mutations: MutationTree<StoresState> = {
  [StoresStoreMutations.SET_STORES]: (state: StoresState, value: Store[]) => {
    state.stores = value;
  },
  [StoresStoreMutations.CLEAR_STORE]: (state: StoresState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialStoreState());
  }
};

const getters: GetterTree<StoresState, RootState> = {
  [StoresStoreGetters.STORES]: (state: StoresState) => state.stores,
};

const storesStore: Module<StoresState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default storesStore;