import { DealerRoles } from '@/enum/DealerRoles.enum';
import { FileType } from '@/enum/FileType.enum';
import Dealer from '@/models/Dealer.model';
import Store from '@/models/Store.model';
import { ApplicationStoreGetters } from '@/store/application.store';
import { AuthStoreGetters } from '@/store/auth.store';
import { DateTime } from 'luxon';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const AuthStore = namespace('auth');
const ApplicationStore = namespace('application');

// Number format object to format decimal value as currency 
// Passing 'undefined' for locale to use system locale 
const currencyFormatter = Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR'   
});

// Number format object to format decimal value as percent with at most 2 fraction digits. 
// Passing 'undefined' for locale to use system locale 
const percentFormatter = Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 2,  
});

@Component
export default class BaseMixin extends Vue {
  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_STORE)
  public currentStore!: Store;

  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  public currentUser!: Dealer;

  // Thresholds for colors in progressbar: 
  public static THRESHOLD_RED = 33;
  public static THRESHOLD_GREEN = 66;

  // Regex for URL (https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url):
  public static URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  public roleRepresentations = {
    [DealerRoles.DEALER]: {
      value: DealerRoles.DEALER,
      title: this.$t('DEALER.ROLES.DEALER').toString(),
      iconColor: 'on-background-lighten-1',
      icon: 'fas fa-user-alt'
    },
    [DealerRoles.STORE_ADMIN]: {
      value: DealerRoles.STORE_ADMIN,
      title: this.$t('DEALER.ROLES.STORE_ADMIN').toString(),
      iconColor: 'info lighten-1',
      icon: 'fas fa-user-tie'
    },
    [DealerRoles.ADMIN]: {
      value: DealerRoles.ADMIN,
      title: this.$t('DEALER.ROLES.ADMIN').toString(),
      iconColor: 'info darken-1',
      icon: 'fas fa-shield-alt'
    },
  };

  public openInNewTab(url: string) {
    // If protocol not set: add `//` to escape current url:
    url = url.match(/^https?:/) ? url : '//' + url;
    if (this.isValidURL(url)) {
      window.open(url);
    } else {
      this.$notifyWarningSimplified('GENERAL.NOTIFICATIONS.LINK_ERROR');
    }
  }

  private isValidURL(url: string): boolean {
    return BaseMixin.URL_REGEX.test(url);
  }

  /**
   * Checks if given type is a video.
   */
  public isTypeVideo(type: FileType): boolean {
    return type == FileType.VIDEO;
  }

  /**
   * Formats given number as currency.
   * @param value Value to be formatted
   * @returns Formatted currency value in euros.
   */
  public getCurrencyFormat(value: number): string {
    return currencyFormatter.format(value);
  }

  /**
   * Formats given number as percent.
   * Assumes that the given value is a percentage (not decimal).
   * @param value Value to be formatted
   * @returns Formatted value in percent with two fractional digits at most.
   */
   public getPercentFormat(value: number): string {
    return percentFormatter.format(value / 100);
  }

  public getDateFormat(value: string): string {
    const date: DateTime = DateTime.fromISO(value);
    return date.toFormat(this.$t('GENERAL.DATE_SHORT').toString());
  }

  public getDateTimeFormat(value: string): string {
    const date: DateTime = DateTime.fromISO(value);
    return date.toFormat(this.$t('GENERAL.DATETIME_SHORT').toString());
  }

  public getRoleRepresentation(role: DealerRoles): { title: string, iconColor: string, icon: string } {
    return this.roleRepresentations[role]!;
  }
}
