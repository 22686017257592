import { AxiosResponse } from 'axios';
import { ENTITY_CLIENT } from '../misc/EntityClient';

export default class InvoiceRepository {
  public static getAllByStoreId(storeId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get(`api/invoices/store/${storeId}`);
  }

  public static getInvoiceFileById(invoiceId: string): Promise<any> {
    return ENTITY_CLIENT.get(`api/invoices/${invoiceId}/download`, {
      responseType: 'blob'
    });
  }
}