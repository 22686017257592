import Parseable from '@/misc/Parseable';
import Deal from './Deal.model';

export default class DealTrackingData extends Parseable {
  public deal!: Deal;
  public numberOfClicks!: number;
  public numberOfUsers!: number;
  public usedBudget!: number;
  public totalBudget?: number;
  public remainingBudget?: number;

  public static parseFromObject(obj: any): DealTrackingData {
    const data = new DealTrackingData();
    Object.assign(data, obj);
    if (data.totalBudget) {
      data.remainingBudget = data.totalBudget - data.usedBudget;
    }
    return data;
  }

  public parseToObject(): DealTrackingData {
    return { ...this };
  }
}