import DealRepository from '@/api/repositories/DealRepository';
import { DealState, RootState } from '@/interfaces/StoreStateInterfaces';
import Deal from '@/models/Deal.model';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { DateTime } from 'luxon';
import Category from '@/models/Category.model';
import Dealer from '@/models/Dealer.model';
import MediaFile from '@/interfaces/MediaFile.interface';
import AssetFile from '@/interfaces/AssetFile.apiEntity';

export const enum DealStoreActions {
  GET_BY_STORE = 'GET_BY_STORE',
  GET_BY_ID = 'GET_BY_ID',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPDATE_WITH_FILES = 'UPDATE_WITH_FILES',
  GET_FILES_FOR_DEAL = 'GET_FILES_FOR_DEAL',
  GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES',
  GET_ICON = 'GET_ICON',
  REACTIVATE = 'REACTIVATE',
}

export const enum DealStoreMutations {
  SET_BY_STORE = 'SET_BY_STORE',
  SET_SELECTED_DEAL = 'SET_SELECTED_DEAL',
  SET_FILES = 'SET_FILES',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_ICON = 'SET_ICON',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum DealStoreGetters {
  DEALS = 'DEALS',
  ACTIVE_DEALS = 'ACTIVE_DEALS',
  INACTIVE_DEALS = 'INACTIVE_DEALS',
  SELECTED_DEAL = 'SELECTED_DEAL',
  FILES = 'FILES',
  ALL_CATEGORIES = 'ALL_CATEGORIES',
  ICON_URL = 'ICON_URL',
}

function initialDealState(): DealState {
  return {
    deals: [],
    currentDeals: [],
    pastDeals: [],
    selectedDeal: undefined,
    files: [],
    categories: [],
    iconURL: '',
  };
}

const store: DealState = initialDealState();

/**
 * Generates a copy of the given deal and sets the attributes according to the
 * requirements of the api.
 * @returns Copy of the given deal with prepared attributes.
 */
function prepareDeal(deal: Deal): Deal {
  const dealCopy = deal.copy() as Deal;
  // Replace object attributes with their ids:
  dealCopy.category = (deal.category as Category).id;
  dealCopy.creator = (deal.creator as Dealer).id!;

  // Convert local time to utc:
  dealCopy.startDate = DateTime.fromISO(dealCopy.startDate!).toUTC().toISO();
  if (dealCopy.endDate) {
    dealCopy.endDate = DateTime.fromISO(dealCopy.endDate).toUTC().toISO();
  }
  return dealCopy;
}

const actions: ActionTree<DealState, RootState> = {
  [DealStoreActions.GET_BY_ID]: async ({ commit }, dealId: string): Promise<Deal> => {
    const response = await DealRepository.getById(dealId);
    const deal = Deal.parseFromObject(response.data) as Deal;
    commit(DealStoreMutations.SET_SELECTED_DEAL, deal);
    return deal;
  },
  [DealStoreActions.GET_BY_STORE]: async ({ commit }, storeId: string): Promise<Deal[]> => {
    const response = await DealRepository.getByStore(storeId);
    const dealsByStore = (Deal.parseFromArray(response.data) as Deal[]).sort(
      (deal1, deal2) => deal1.updatedAt! > deal2.updatedAt! ? -1 : 1 // sort ascending by last update
    );
    commit(DealStoreMutations.SET_BY_STORE, dealsByStore);
    return dealsByStore;
  },
  [DealStoreActions.GET_FILES_FOR_DEAL]: async ({ commit }, deal: Deal): Promise<MediaFile[]> => {
    // https://stackoverflow.com/questions/41292316/how-do-i-await-multiple-promises-in-parallel-without-fail-fast-behavior
    // https://codereview.stackexchange.com/questions/147455/parallel-and-sequential-array-looping-with-async-await
    const files: MediaFile[] = [];
    const promises = deal.files.map(file => DealRepository.getFileById(file.id));
    for (let i=0; i < deal.files.length; i++) {
      const file = (await promises[i]).data;
      files.push({ fileId: deal.files[i].id, fileURL: URL.createObjectURL(file), type: deal.files[i].type, format: file.type });
      commit(DealStoreMutations.SET_FILES, files);
    }
    commit(DealStoreMutations.SET_FILES, files);
    return files;
  },
  [DealStoreActions.GET_ALL_CATEGORIES]: async ({ commit }): Promise<Category[]> => {
    const response = await DealRepository.getAllCategories();
    const categories = Category.parseFromArray(response.data) as Category[];
    commit(DealStoreMutations.SET_CATEGORIES, categories);
    return categories;
  },
  [DealStoreActions.CREATE]: async ({ commit }, payload: { deal: Deal, files?: File[] }): Promise<Deal> => {
    const dealCopy = prepareDeal(payload.deal);

    const createResponse = await DealRepository.create(dealCopy);
    let updatedDealObj = createResponse.data;

    // Upload Files:
    if (payload.files) {
      // With each file upload, the deal object is updated to add the fileId to deal.files
      // The last object contains all files
      for (const file of payload.files) {
        updatedDealObj = (await DealRepository.uploadFile(updatedDealObj.id, file)).data;
      }
    }
    return Deal.parseFromObject(updatedDealObj) as Deal;
  },
  [DealStoreActions.UPDATE]: async ({ commit }, deal: Deal): Promise<Deal> => {
    const dealCopy = prepareDeal(deal);

    const updateResponse = await DealRepository.update(dealCopy);
    return Deal.parseFromObject(updateResponse.data) as Deal;
  },
  [DealStoreActions.UPDATE_WITH_FILES]: async ({ commit, state }, payload: { deal: Deal, filesToAdd: File[], selectedFileObjs: AssetFile[] }): Promise<Deal> => {
    const dealCopy = prepareDeal(payload.deal);

    const filesBefore: AssetFile[] = dealCopy.files;
    
    // Set file ids:
    dealCopy.files = payload.selectedFileObjs;

    const updateResponse = await DealRepository.update(dealCopy);

    let updatedDealObj = updateResponse.data;

    // Determine which files need to be removed:
    for (const fileBefore of filesBefore) {
      const fileAfter: AssetFile | undefined = payload.selectedFileObjs.find(file => file.id === fileBefore.id);
      if (!fileAfter) {
        // Remove File:
        updatedDealObj = (await DealRepository.removeFile(payload.deal.id, fileBefore.id!)).data;
      }
    }

    // Add new files:
    for (const file of payload.filesToAdd) {
      updatedDealObj = (await DealRepository.uploadFile(updatedDealObj.id, file)).data;
    }

    const updatedDeal = Deal.parseFromObject(updatedDealObj);
    commit(DealStoreMutations.SET_SELECTED_DEAL, updatedDeal);
    return updatedDeal;
  },
  [DealStoreActions.GET_ICON]: async ({ commit }, dealId: string): Promise<string> => {
    const response = await DealRepository.getIcon(dealId);
    const iconURL = response.data;
    commit(DealStoreMutations.SET_ICON, iconURL);
    return iconURL;
  },
  [DealStoreActions.REACTIVATE]: async ({ commit, state }, payload: { deal: Deal, filesToAdd: File[], selectedFileObjs: AssetFile[] }) => {
    // Replace object attributes with their ids:
    const deal = payload.deal.copy() as Deal;
    deal.category = (payload.deal.category as Category).id;
    deal.creator = (payload.deal.creator as Dealer).id!;

    const filesBefore: AssetFile[] = deal.files;
    
    // Set file ids:
    deal.files = payload.selectedFileObjs;

    const reactivateResponse = await DealRepository.reactivate(deal);

    let reactivatedDealObj = reactivateResponse.data;

    // Determine which files need to be removed:
    for (const fileBefore of filesBefore) {
      const fileAfter: AssetFile | undefined = payload.selectedFileObjs.find(file => file.id === fileBefore.id);
      if (!fileAfter) {
        // Remove File:
        reactivatedDealObj = (await DealRepository.removeFile(reactivatedDealObj.id, fileBefore.id!)).data;
      }
    }

    // Add new files:
    for (const file of payload.filesToAdd) {
      reactivatedDealObj = (await DealRepository.uploadFile(reactivatedDealObj.id, file)).data;
    }

    const reactivatedDeal = Deal.parseFromObject(reactivatedDealObj);
    commit(DealStoreMutations.SET_SELECTED_DEAL, reactivatedDeal);
    return reactivatedDeal;
  },
};

const mutations: MutationTree<DealState> = {
  [DealStoreMutations.SET_BY_STORE]: (state: DealState, value: Deal[]) => {
    state.deals = value;
  },
  [DealStoreMutations.SET_SELECTED_DEAL]: (state: DealState, value: Deal) => {
    state.selectedDeal = value;
  },
  [DealStoreMutations.SET_FILES]: (state: DealState, value: any[]) => {
    state.files = value;
  },
  [DealStoreMutations.SET_CATEGORIES]: (state: DealState, value: Category[]) => {
    state.categories = value;
  },
  [DealStoreMutations.SET_ICON]: (state: DealState, value: string) => {
    state.iconURL = value;
  },
  [DealStoreMutations.CLEAR_STORE]: (state: DealState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialDealState());
  }
};

const getters: GetterTree<DealState, RootState> = {
  [DealStoreGetters.DEALS]: (state: DealState) => {
    return state.deals;
  },
  [DealStoreGetters.ACTIVE_DEALS]: (state: DealState) => 
    state.deals.filter(deal => deal.status),
  [DealStoreGetters.INACTIVE_DEALS]: (state: DealState) => 
    state.deals.filter(deal => !deal.status),
  [DealStoreGetters.SELECTED_DEAL]: (state: DealState) => state.selectedDeal,
  [DealStoreGetters.FILES]: (state: DealState) => state.files,
  [DealStoreGetters.ALL_CATEGORIES]: (state: DealState) => state.categories,
  [DealStoreGetters.ICON_URL]: (state: DealState) => `${process.env.VUE_APP_API_URL}${state.iconURL}`,
};

const dealsStore: Module<DealState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default dealsStore;