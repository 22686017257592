import { Vue } from 'vue-property-decorator';
import axios, { AxiosInstance } from 'axios';
import ApiError from './ApiError';
import GeneralApiError from './GeneralApiError';

const baseURL: string = process.env.VUE_APP_API_URL;
const axiosClient: AxiosInstance = axios.create({
  baseURL: baseURL,
});

/**
 * Sets the response interceptors. Normalizes the response and handles certain error cases.
 */
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status >= 500) {
        Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        throw new GeneralApiError(error.response.status, error.message, error.response.data.data);
      } else {
        throw new ApiError(error.response.status, error.message, error.response.data.data);
      }
    } else {
      Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
    }
    return new Promise(() => {
      // cancels processing of the interceptor chain
    });
  }
);

export const AUTH_CLIENT = axiosClient;
