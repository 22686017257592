import TrackingRepository from '@/api/repositories/TrackingRepository';
import { DealTrackingDataState, RootState } from '@/interfaces/StoreStateInterfaces';
import DealTrackingData from '@/models/DealTrackingData.model';
import { DateTime } from 'luxon';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const enum DealTrackingDataStoreActions {
  GET_BY_STORE = 'GET_BY_STORE',
  GET_BY_DEAL = 'GET_BY_DEAL'
}

export const enum DealTrackingDataStoreMutations {
  SET_ITEMS_BY_STORE = 'SET_ITEMS_BY_STORE',
  SET_ITEM_BY_DEAL = 'SET_ITEM_BY_DEAL',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum DealTrackingDataStoreGetters {
  ITEMS_BY_STORE = 'ITEMS_BY_STORE',
  ITEM_BY_DEAL = 'ITEM_BY_DEAL',
  TOTAL_NUMBER_OF_CLICKS = 'TOTAL_NUMBER_OF_CLICKS',
  TOTAL_NUMBER_OF_USERS = 'TOTAL_NUMBER_OF_USERS',
  TOTAL_BUDGET = 'TOTAL_BUDGET',
  TOTAL_USED_BUDGET = 'TOTAL_USED_BUDGET',
  TOTAL_REMAINING_BUDGET = 'TOTAL_REMAINING_BUDGET'
}

function initialDealTrackingDataState(): DealTrackingDataState {
  return {
    itemsByStore: [],
    itemByDeal: undefined
  };
}

const store: DealTrackingDataState = initialDealTrackingDataState();

const actions: ActionTree<DealTrackingDataState, RootState> = {
  [DealTrackingDataStoreActions.GET_BY_STORE]: async ({ commit }, payload: { storeId: string, fromDate: DateTime, toDate: DateTime }): Promise<DealTrackingData[]> => { 
    const response = await TrackingRepository.getByStore(payload.storeId, payload.fromDate, payload.toDate);
    const data = DealTrackingData.parseFromArray(response.data) as DealTrackingData[];
    commit(DealTrackingDataStoreMutations.SET_ITEMS_BY_STORE, data);
    return data;
  },
  [DealTrackingDataStoreActions.GET_BY_DEAL]: async ({ commit }, dealId: string): Promise<DealTrackingData> => {
    const response = await TrackingRepository.getByDeal(dealId);
    const data = DealTrackingData.parseFromObject(response.data) as DealTrackingData;
    commit(DealTrackingDataStoreMutations.SET_ITEM_BY_DEAL, data);
    return data;
  }
};

const mutations: MutationTree<DealTrackingDataState> = {
  [DealTrackingDataStoreMutations.SET_ITEMS_BY_STORE]: (state: DealTrackingDataState, value: DealTrackingData[]) => {
    state.itemsByStore = value;
  },
  [DealTrackingDataStoreMutations.SET_ITEM_BY_DEAL]: (state: DealTrackingDataState, value: DealTrackingData) => {
    state.itemByDeal = value;
  },
  [DealTrackingDataStoreMutations.CLEAR_STORE]: (state: DealTrackingDataState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialDealTrackingDataState());
  }
};

const getters: GetterTree<DealTrackingDataState, RootState> = {
  [DealTrackingDataStoreGetters.ITEMS_BY_STORE]: (state: DealTrackingDataState) => state.itemsByStore,
  [DealTrackingDataStoreGetters.ITEM_BY_DEAL]: (state: DealTrackingDataState) => state.itemByDeal,
  [DealTrackingDataStoreGetters.TOTAL_NUMBER_OF_CLICKS]: (state: DealTrackingDataState) => {
    return state.itemsByStore.reduce((sum, b) => sum + b.numberOfClicks, 0);
  },
  [DealTrackingDataStoreGetters.TOTAL_NUMBER_OF_USERS]: (state: DealTrackingDataState) => {
    return state.itemsByStore.reduce((sum, b) => sum + b.numberOfUsers, 0);
  },
  [DealTrackingDataStoreGetters.TOTAL_BUDGET]: (state: DealTrackingDataState) => {
    return state.itemsByStore.filter(item => item.totalBudget).reduce((sum, b) => sum + b.totalBudget!, 0);
  },
  [DealTrackingDataStoreGetters.TOTAL_USED_BUDGET]: (state: DealTrackingDataState) => {
    return state.itemsByStore.reduce((sum, b) => sum + b.usedBudget, 0);
  },
  [DealTrackingDataStoreGetters.TOTAL_REMAINING_BUDGET]: (state: DealTrackingDataState) => {
    return state.itemsByStore.filter(item => item.remainingBudget).reduce((sum, b) => sum + b.remainingBudget!, 0);
  },
};

const dataStore: Module<DealTrackingDataState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default dataStore;