import AuthRepository from '@/api/repositories/AuthRepository';
import { AuthState, RootState } from '@/interfaces/StoreStateInterfaces';
import Dealer from '@/models/Dealer.model';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const enum AuthStoreActions {
  LOGIN = 'LOGIN',
  INITIATE_PASSWORD_RESET = 'INITIATE_PASSWORD_RESET',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export const enum AuthStoreMutations {
  SET_TOKEN = 'SET_TOKEN',
  SET_USER = 'SET_USER',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum AuthStoreGetters {
  TOKEN = 'TOKEN',
  CURRENT_USER = 'CURRENT_USER',
}

function initialAuthState(): AuthState {
  return {
    token: undefined,
    currentUser: undefined,
  };
}

const store: AuthState = initialAuthState();

const actions: ActionTree<AuthState, RootState> = {
  [AuthStoreActions.LOGIN]: async ({ commit }, payload: { email: string, password: string }): Promise<Dealer> => {
    const response = await AuthRepository.login(payload);
    // Save token:
    commit(AuthStoreMutations.SET_TOKEN, response.data.token);
    // Save user:
    const user = Dealer.parseFromObject({ ...response.data.dealer });
    commit(AuthStoreMutations.SET_USER, user);
    return user;
  },
  [AuthStoreActions.INITIATE_PASSWORD_RESET]: async ({ commit }, email: string): Promise<any> => {
    const response = await AuthRepository.initiatePasswordReset(email);
    return response.data;
  },
  [AuthStoreActions.RESET_PASSWORD]: async ({ commit }, payload: { token: string, password: string }): Promise<any> => {
    const response = await AuthRepository.resetPassword(payload.token, payload.password);
    return response.data;
  }
};

const mutations: MutationTree<AuthState> = {
  [AuthStoreMutations.SET_TOKEN]: (state: AuthState, value: string | undefined) => {
    state.token = value;
  },
  [AuthStoreMutations.SET_USER]: (state: AuthState, value: Dealer) => {
    state.currentUser = value;
  },
  [AuthStoreMutations.CLEAR_STORE]: (state: AuthState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialAuthState());
  }
};

const getters: GetterTree<AuthState, RootState> = {
  [AuthStoreGetters.TOKEN]: (state: AuthState) => state.token,
  [AuthStoreGetters.CURRENT_USER]: (state: AuthState) => {
    if (state.currentUser) {
      // needs to be parsed because VuexPersistence does not keep type information
      return Dealer.parseFromObject(state.currentUser);
    }
    return undefined;
  },  
};

const authStore: Module<AuthState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default authStore;
