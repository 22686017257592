/**
 * All application routes are collected here
 */
export const ROUTE_LOGIN = 'login';
export const ROUTE_HOME = 'home';
export const ROUTE_STORE = 'store';
export const ROUTE_DEALER = 'dealer';
export const ROUTE_DASHBOARD = 'dashboard';
export const ROUTE_STORES = 'stores';
export const ROUTE_DEALS = 'deals';
export const ROUTE_DEAL = 'deal';
export const ROUTE_INVOICES = 'invoices';
export const ROUTE_TRACKING = 'tracking';
export const ROUTE_REGISTER = 'register';
export const ROUTE_PASSWORD = 'password';