import Dealer from '@/models/Dealer.model';
import { AxiosResponse } from 'axios';
import { ENTITY_CLIENT } from '../misc/EntityClient';

export default class DealerRepository {
  public static getAll(): Promise<AxiosResponse> {
    return ENTITY_CLIENT.get('api/dealer');
  }

  public static create(dealer: Dealer): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post('api/dealer', dealer);
  }

  public static update(dealer: Dealer): Promise<AxiosResponse> {
    return ENTITY_CLIENT.put(`api/dealer/${dealer.id}`, dealer);
  }

  public static register(dealer: Dealer): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post('dealer', dealer);
  }

  public static resendSetPassword(dealerId: string): Promise<AxiosResponse> {
    return ENTITY_CLIENT.post(`api/dealer/${dealerId}/resendSetPassword`);
  }
}