import { DateTime } from 'luxon';

export default abstract class Parseable {
  public static parseFromObject(object: any): Parseable {
    // static abstract methods are not allowed in ts :(
    throw new Error('Overwrite me!!');
  }

  public static parseFromArray(array: any[]): Parseable[] {
    if (!Array.isArray(array)) {
      return [];
    }
    return array.map((obj) => this.parseFromObject(obj));
  }

  public abstract parseToObject(): any;

  /**
   * Creates a deep copy.
   */
  public copy(): Parseable {
    const copy = this.deepCopy(this);
    return (this.constructor as any).parseFromObject(copy);
  }

  // https://stackoverflow.com/questions/28150967/typescript-cloning-object
  private deepCopy(obj: any): any {
    // Handle primary types, and null or undefined:
    if (obj == null || typeof obj != 'object') return obj;

    if (obj instanceof DateTime) {
      return DateTime.fromISO(obj.toISO());
    }

    if (obj instanceof Array) {
      const copy = [];
      for (let i = 0; i < obj.length; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    if (obj instanceof Object) {
      const copy: any = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw Error(`Unable to copy object ${obj}! Type is not supported.`);
  }
}
