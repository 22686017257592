import DealerRepository from '@/api/repositories/DealerRepository';
import { DealerState, RootState } from '@/interfaces/StoreStateInterfaces';
import Dealer from '@/models/Dealer.model';
import Store from '@/models/Store.model';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const enum DealerStoreActions {
  GET_ALL = 'GET_ALL',
  GET_BY_STORE = 'GET_BY_STORE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  REGISTER_DEALER = 'REGISTER_DEALER',
  RESEND_SET_PASSWORD = 'RESEND_SET_PASSWORD'
}

export const enum DealerStoreMutations {
  SET_DEALERS = 'SET_DEALERS',
  SET_DEALER_BY_STORE = 'SET_DEALER_BY_STORE',
  SET_CURRENT_DEALER = 'SET_CURRENT_DEALER',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum DealerStoreGetters {
  DEALERS = 'DEALERS',
  DEALER_BY_STORE = 'DEALER_BY_STORE',
  CURRENT_DEALER = 'CURRENT_DEALER'
}

function initialDealerState(): DealerState {
  return {
    dealers: [],
    dealerByStore: [],
    currentDealer: undefined
  };
}

const store: DealerState = initialDealerState();

const actions: ActionTree<DealerState, RootState> = {
  [DealerStoreActions.GET_ALL]: async ({ commit }): Promise<Dealer[]> => {
    const response = await DealerRepository.getAll();
    const dealers = Dealer.parseFromArray(response.data) as Dealer[];
    commit(DealerStoreMutations.SET_DEALERS, dealers);
    return dealers;
  },
  [DealerStoreActions.GET_BY_STORE]: async ({ commit }, storeId: string): Promise<Dealer[]> => {
    const response = await DealerRepository.getAll();
    const dealerByStore = (Dealer.parseFromArray(response.data) as Dealer[]).filter(dealer => dealer.store && (dealer.store as Store).id === storeId);
    commit(DealerStoreMutations.SET_DEALER_BY_STORE, dealerByStore);
    return dealerByStore;
  },
  [DealerStoreActions.CREATE]: async ({ commit }, dealer: Dealer): Promise<Dealer> => {
    // Replace object attributes with their ids:
    const apiDealer = dealer.copy() as Dealer;
    apiDealer.store = (dealer.store as Store).id!;
    
    const response = await DealerRepository.create(apiDealer);
    const updatedDealer = Dealer.parseFromObject(response.data) as Dealer;
    commit(DealerStoreMutations.SET_CURRENT_DEALER, updatedDealer);
    return updatedDealer;
  },
  [DealerStoreActions.UPDATE]: async ({ commit }, dealer: Dealer): Promise<Dealer> => {
    // Replace object attributes with their ids:
    const apiDealer = dealer.copy() as Dealer;
    apiDealer.store = (dealer.store as Store).id!;
    
    const response = await DealerRepository.update(apiDealer);
    const updatedDealer = Dealer.parseFromObject(response.data) as Dealer;
    commit(DealerStoreMutations.SET_CURRENT_DEALER, updatedDealer);
    return updatedDealer;
  },
  [DealerStoreActions.REGISTER_DEALER]: async ({ commit }, dealer: Dealer): Promise<Dealer> => {
    // Replace object attributes with their ids:
    const apiDealer = dealer.copy() as Dealer;
    apiDealer.store = (dealer.store as Store).id!;
    
    const response = await DealerRepository.register(apiDealer);
    const updatedDealer = Dealer.parseFromObject(response.data) as Dealer;
    commit(DealerStoreMutations.SET_CURRENT_DEALER, updatedDealer);
    return updatedDealer;
  },
  [DealerStoreActions.RESEND_SET_PASSWORD]: async ({ commit }, dealerId: string): Promise<any> => {
    const response = await DealerRepository.resendSetPassword(dealerId);
    return response.data;
  }
};

const mutations: MutationTree<DealerState> = {
  [DealerStoreMutations.SET_DEALERS]: (state: DealerState, value: Dealer[]) => {
    state.dealers = value;
  },
  [DealerStoreMutations.SET_DEALER_BY_STORE]: (state: DealerState, value: Dealer[]) => {
    state.dealerByStore = value;
  },
  [DealerStoreMutations.SET_CURRENT_DEALER]: (state: DealerState, value: Dealer) => {
    state.currentDealer = value;
  },
  [DealerStoreMutations.CLEAR_STORE]: (state: DealerState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialDealerState());
  }
};

const getters: GetterTree<DealerState, RootState> = {
  [DealerStoreGetters.DEALERS]: (state: DealerState) => state.dealers,
  [DealerStoreGetters.DEALER_BY_STORE]: (state: DealerState) => state.dealerByStore,
  [DealerStoreGetters.CURRENT_DEALER]: (state: DealerState) => state.currentDealer
};

const authStore: Module<DealerState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default authStore;