import { DisplayType } from '@/enum/DisplayType';
import { ExpiryReason } from '@/enum/ExpiryReason.enum';
import { FileType } from '@/enum/FileType.enum';
import AssetFile from '@/interfaces/AssetFile.apiEntity';
import Parseable from '@/misc/Parseable';
import Category from './Category.model';
import Dealer from './Dealer.model';
import Store from './Store.model';

export default class Deal extends Parseable {
  public id!: string;
  public title!: string;
  public description!: string;
  public oldPrice?: number;
  public price!: number;
  public displayType: DisplayType = DisplayType.PRICE;
  public startDate?: string;
  public endDate?: string;
  public category?: Category | string; // type string for sending id to backend
  public store!: string;
  public files!: AssetFile[];
  public creator!: Dealer | string;
  public status: boolean = true;
  public expiryReason: ExpiryReason = ExpiryReason.NOT_EXPIRED;
  public budget?: number;
  public review?: { average: number, count: number };
  public adult: boolean = false;
  public updatedAt?: string;

  public constructor(store?: Store, creator?: Dealer) {
    super();
    if (store) this.store = store.id!;
    if (creator) this.creator = creator;
  }

  public static parseFromObject(object: any): Deal {
    const deal = new Deal();
    Object.assign(deal, object);

    deal.category = Category.parseFromObject(object.category);
    deal.creator = Dealer.parseFromObject(object.creator);

    return deal;
  }

  public parseToObject() {
    return { ...this };
  }
}