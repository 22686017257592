import Parseable from '@/misc/Parseable';
import { DateTime } from 'luxon';

export default class Review extends Parseable {
  public user!: string;
  public deal!: string;
  public rating!: number;
  public text?: string;
  public createdAt!: DateTime;

  public static parseFromObject(object: any): Review {
    const review = new Review();
    Object.assign(review, object);
    
    review.createdAt = DateTime.fromISO(object.createdAt);
    
    return review;
  }

  public parseToObject(): Review {
    return { ...this };
  }
}