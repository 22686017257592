import { Vue } from 'vue-property-decorator';
import router from '@/router';
import { ROUTE_LOGIN } from '@/router/routes';
import store from '@/store';
import { AuthStoreGetters } from '@/store/auth.store';
import axios, { AxiosInstance } from 'axios';
import ApiError from './ApiError';
import GeneralApiError from './GeneralApiError';

const baseURL: string = process.env.VUE_APP_API_URL;
const axiosClient: AxiosInstance = axios.create({
  baseURL: baseURL,
});

/**
 * Sets the request interceptors.
 */
axiosClient.interceptors.request.use(
  (config) => {
    // OPTIMIZE this is not optimal, the token could be expired ...
    if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
      config.headers.authorization = `Bearer ${store.getters[`auth/${AuthStoreGetters.TOKEN}`]}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Sets the response interceptors. Normalizes the response and handles certain error cases.
 */
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401: // Unauthorized
        case 403: // Forbidden
          // Reset all stores:
          Object
            // @ts-ignore
            .keys(store._modulesNamespaceMap)
            .forEach(key => {
              store.commit(`${key}CLEAR_STORE`);
            });
          router.push({ name: ROUTE_LOGIN });
          break;
        case 500: // any server error
          Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
          throw new GeneralApiError(error.response.status, error.message, error.response.data.data);
        default:
          throw new ApiError(error.response.status, error.message, error.response.data.data);
      }
    } else {
      Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
    }
    return new Promise(() => {
      // cancels processing of the interceptor chain
    });
  }
);

export const ENTITY_CLIENT = axiosClient;
