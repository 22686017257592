import Parseable from '@/misc/Parseable';

export default class Category extends Parseable {
  public id!: string;
  public name!: string;
  public categories!: Category[];
  public status!: boolean;
  public price!: number;

  public static parseFromObject(object: any): Category {
    const category = new Category();
    Object.assign(category, object);
    return category;
  }

  public parseToObject() {
    return { ...this };
  }
}