import { RootState, ApplicationState } from '@/interfaces/StoreStateInterfaces';
import Store from '@/models/Store.model';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const enum ApplicationStoreActions {}

export const enum ApplicationStoreMutations {
  SET_STORE = 'SET_STORE',
  CLEAR_STORE = 'CLEAR_STORE'
}

export const enum ApplicationStoreGetters {
  CURRENT_STORE = 'CURRENT_STORE',
}

function initialApplicationState(): ApplicationState {
  return {
    currentStore: undefined,
  };
}

const store: ApplicationState = initialApplicationState();

const actions: ActionTree<ApplicationState, RootState> = {};

const mutations: MutationTree<ApplicationState> = {
  [ApplicationStoreMutations.SET_STORE]: (state: ApplicationState, value: Store) => {
    state.currentStore = value;
  },
  [ApplicationStoreMutations.CLEAR_STORE]: (state: ApplicationState) => {
    // Merge rather than replace so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialApplicationState());
  },
};

const getters: GetterTree<ApplicationState, RootState> = {
  [ApplicationStoreGetters.CURRENT_STORE]: (state: ApplicationState) => state.currentStore,
};

const applicationStore: Module<ApplicationState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default applicationStore;