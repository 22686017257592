

























import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';

@Component
export default class App extends mixins(BaseMixin) {
  private imprintURL: string = `${process.env.VUE_APP_IMPRINT_URL}`;
  private dataPrivacyURL: string = `${process.env.VUE_APP_DATA_PRIVACY_URL}`;
  private termsAndConditionsURL: string = `${process.env.VUE_APP_TERMS_AND_CONDITIONS_URL}`;
}
